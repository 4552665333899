<template>

    <div class="admin bodySpacer verticalSpacer">
      <AdminNav />
      <div class="adminBlock">
        <div class="" v-if="this.user.attributes['custom:role'] === 'admin'">
            <div class="adminHeader">
              <h2>Statistics:</h2>
              <GroupStats :groups="allGroups"/>
            </div>
        </div>
        <div class="statInfo">
          <p class="info">
            <strong>Started Tracking:</strong> <br />
              Downloads: Febuary 2024 <br />
              Logins: March 2024 <br />
              Track Views: March 2024 <br />
              Video Events: April 2024 <br />
              Search Events: April 2024 <br />
              Track Complete (all videos started): October 2024 <br />
          </p>
        </div>
      </div>
    </div>

</template>

<script>
import AmplifyStore from '@/store/store'
import { API } from 'aws-amplify'
import AdminNav from '@/components/admin/components/AdminNav'
import GroupStats from '@/components/admin/components/GroupStats'

export default {
  name: 'SystemStats',
  components: {AdminNav, GroupStats},
  data () {
    return {
    }
  },
  methods: {
    
  },
  created () {
    // Initialize admin
    AmplifyStore.dispatch('admin/getAllGroups')
  },
  unmounted () {
    AmplifyStore.commit('admin/set_allGroups', false)
  },
  computed: {
    user() { return AmplifyStore.state.user },
    cognito() { return AmplifyStore.state.cognito },
    allGroups() { return AmplifyStore.state.admin.allGroups },
  }
}
</script>

<style scoped>

</style>
