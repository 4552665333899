<template>
    <div class="stats-header">
        <h6>Organization Analytics</h6>
        <div class="inputWrap">
            <select id="" v-model="selectedRange">
                <option v-for="opt in rangeSelection" :key="opt.val" :value="opt.val">{{ opt.text}}</option>
            </select>
            <button @click.prevent="getAnalytics">Update</button>
        </div>
    </div>
    <div class="overview">
        <p>Active users: {{ countLogins.uniquesCount }} of <span v-if="memberCount">{{ memberCount }}</span> <span v-if="!memberCount">{{ sumRegisteredUsers }}</span></p>
        <p>Total Logins: {{ countLogins?.loginCount }}</p>
        <p>Account Sign Ups: {{ countLogins?.signupCount }}</p>
    </div>
    <div class="lists">
        <div class="topTracks">
            <h3>Track <span>Views: {{ viewTracks?.count }}</span></h3>
            <ul>
                <li v-for="(t, name) in viewTracks.list"> <strong>{{ t }}x</strong> {{ trackNiceName(name) }} </li>
            </ul>
        </div>
        <div class="topVideos">
            <h3>Video <span>Starts: {{ videoStarts?.count }}</span></h3>
            <ul>
                <li v-for="(v, name) in videoStarts.list"> <strong>{{ v }}x</strong> {{ name }}</li>
            </ul>
            <h3>Video <span>Completes: {{ videoCompletes?.count }}</span></h3>
            <ul>
                <li v-for="(v, name) in videoCompletes.list"> <strong>{{ v }}x</strong> {{ name }}</li>
            </ul>
            <h3>File <span>Downloads: {{ viewDownloads?.count }}</span></h3>
            <ul>
                <li v-for="(v, name) in viewDownloads.list"> <strong>{{ v }}x</strong> {{ name }}</li>
            </ul>
        </div>
    </div>

    <!-- <div v-for="event in eventData" :key="event.time">
        {{ event }}
    </div> -->
</template>

<script>
    import AmplifyStore from '@/store/store'
    import * as dayjs from 'dayjs'
    import * as utc from 'dayjs/plugin/utc'
    import * as dayOfYear from 'dayjs/plugin/dayOfYear'
    dayjs.extend(utc)
    dayjs.extend(dayOfYear)

    export default {
        name: 'GroupStats',
        props: {
            groupName: {
                type: String,
                required: false,
            }, 
            memberCount: {
                type: Number,
                required: false,
            },
            groups: {
                required: false,
            }
        },
        data () {
            return {
                rangeSelection: [
                    { "val": "0", "text": "Last 30 Days" },
                    { "val": "1", "text": "Last 365 Days" },
                    { "val": "2", "text": "Month to Date" },
                    { "val": "3", "text": "Year to Date" },
                ],
                selectedRange: '1',
                eventData: null,
                todayIs: dayjs().utc(),
            }
        },
        mounted () {
            this.getAnalytics();
            if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
        },
        methods: {
            getAnalytics: function() {
                let fromDate = null
                let days = null
                this.eventData = null
                switch (this.selectedRange) {
                    case '0': 
                        fromDate = this.todayIs.subtract(30, 'day').format()
                        break;
                    case '1': 
                        fromDate = this.todayIs.subtract(365, 'day').format()
                        break;
                    case '2':
                        days = this.todayIs.date()
                        fromDate = this.todayIs.subtract(days, 'day').format()
                        break;
                    case '3':
                        days = this.todayIs.dayOfYear()
                        fromDate = this.todayIs.subtract(days, 'day').format()
                        break;
                }
                
                // just one org
                if(this.groupName && fromDate && fromDate != '') {
                    this.getData(fromDate, this.groupName)
                }
                // get all orgs
                if(this.groups && fromDate && fromDate != '') {
                    this.groups.forEach(g => {
                        this.getData(fromDate, g.slug, true)
                    })
                }
            },
            getData: function(timeframe, org, append){
                let that = this
                let params = {
                    TableName : `${this.ENV.tablePrefix}EventLog`,
                    KeyConditionExpression: '#group_name = :group_slug AND #time_string > :start_date',
                    ExpressionAttributeValues: {
                        ':start_date': timeframe,
                        ':group_slug': org
                    },
                    ExpressionAttributeNames: { "#group_name": "group", "#time_string": "time" }
                };
                if(!append){
                    this.docClient.query(params, function(err, data) {
                        if (err) { console.log(err) }
                        else { that.eventData = data.Items }
                    });
                }
                if(append){
                    this.docClient.query(params, function(err, data) {
                        if (err) { console.log(err) }
                        else { 
                            if(!that.eventData) that.eventData = []
                            that.eventData = [...that.eventData, ...data.Items]
                         }
                    });
                }
            },
            trackNiceName(logSlug){
                return this.tracks.find(t => {return t.slug === logSlug}).title
            },
        },
        computed: {
            ENV() { return AmplifyStore.state.ENV },
            user() { return AmplifyStore.state.user },
            docClient() { return AmplifyStore.state.docClient },
            tracks() { return AmplifyStore.state.private.allTracks },
            countLogins() {
                let result = {}
                result.uniqueLogins = {}
                if(this.eventData) {
                    let logins = this.eventData.filter( x => { return x.event === '500' })
                    let signups = this.eventData.filter( x => { return x.event === '510' })
                    let uniques = []

                    logins.forEach( l => {
                        if(!uniques.some(x => x.user === l.user)) {
                            uniques.push(l)
                        }
                    })

                    result.loginCount = logins.length
                    result.signupCount = signups.length
                    result.uniquesCount = uniques.length
                } 
                return result
            },
            viewTracks() {
                let trackviews = null
                let result = {}
                let viewsList = {}
                if(this.eventData) {
                    trackviews = this.eventData.filter( x => { return x.event === '100' })
                    result.count = trackviews.length;
                    trackviews.forEach((t) => {
                        viewsList[t.detail] = (viewsList[t.detail] || 0) + 1;
                    })
                    result.list = Object.fromEntries(
                        Object.entries(viewsList).sort(([,a],[,b]) => b-a)
                    )
                } 
                return result
            },
            videoCompletes() {
                let videoViews = null
                let result = {}
                let viewsList = {}
                if(this.eventData) {
                    videoViews = this.eventData.filter( x => { return x.event === '220' })
                    result.count = videoViews.length;
                    videoViews.forEach((t) => {
                        viewsList[t.detail] = (viewsList[t.detail] || 0) + 1;
                    })
                    result.list = Object.fromEntries(
                        Object.entries(viewsList).sort(([,a],[,b]) => b-a)
                    )
                } 
                return result
            },
            videoStarts() {
                let videoViews = null
                let result = {}
                let viewsList = {}
                if(this.eventData) {
                    videoViews = this.eventData.filter( x => { return x.event === '200' })
                    result.count = videoViews.length;
                    videoViews.forEach((t) => {
                        viewsList[t.detail] = (viewsList[t.detail] || 0) + 1;
                    })
                    result.list = Object.fromEntries(
                        Object.entries(viewsList).sort(([,a],[,b]) => b-a)
                    )
                } 
                return result
            },
            viewDownloads() {
                let downloads = null
                let result = {}
                let viewsList = {}
                if(this.eventData) {
                    downloads = this.eventData.filter( x => { return x.event === '300' })
                    result.count = downloads.length;
                    downloads.forEach((t) => {
                        viewsList[t.detail] = (viewsList[t.detail] || 0) + 1;
                    })
                    result.list = Object.fromEntries(
                        Object.entries(viewsList).sort(([,a],[,b]) => b-a)
                    )
                } 
                return result
            },
            sumRegisteredUsers() {
                let counter = 0
                if(this.groups) {
                    this.groups.forEach((g) => {
                        counter = counter += g.members.length;
                    })
                }
                return counter
            }
        }
    }
</script>

<style scoped lang="scss">
    .stats-header {
        background-color: var(--color-gray2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        > * {
            margin-bottom: 0;
        }
    }
    .inputWrap {
        display: flex;
        gap: 1rem;
        
        select {
            min-width: 150px;
        }
    }
    .lists {
        display: flex;
        gap: 2rem;
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid var(--color-gray5);
    }
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        
        li {
            padding: 0.5rem 1rem;
            display: flex;
            gap: 1rem;
        }
        li:nth-child(odd) {
            background-color: var(--color-gray1);
        }
    }
    .overview {
        padding-top: 2rem;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;

        h2 {
            flex-basis: 100%;
        }
        p {
            flex-basis: calc(33.33% - 2rem);
            font-size: 2rem;
        }
    }
</style>